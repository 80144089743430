/**
 * Sort sources by label
 *
 * @method sortSources
 * @private
 *
 * @param  {Array} sources - sources for player
 *
 * @returns {Array} sorted sources
 *
 */
export default function sortSources(sources) {
  if (sources && sources.length > 2) {
    return sources.sort((a, b) => parseInt(b.label, 10) - parseInt(a.label, 10));
  }

  return sources;
}
