export default function appendTypeToSources(sources) {
  const filteredSources = sources.filter(({ src, type = '' }) => {
    if (!src) {
      return false;
    }
    const sourcePathname = new URL(src).pathname;
    return sourcePathname.match(/\.(mp4|m3u8)$/) || type.match('mp4');
  });

  return filteredSources.map(({ src, type = '', label = '', selected = false }) => {
    let actualType;
    const sourcePathname = new URL(src).pathname;
    if (sourcePathname.match(/\.mp4$/) || type.match('mp4')) {
      actualType = 'video/mp4';
    } else if (sourcePathname.match(/\.m3u8$/)) {
      actualType = 'application/x-mpegURL';
    }

    return {
      src,
      label,
      type : actualType,
      selected
    };
  });
}
