export default class PlayerCTA {
  constructor(ctaSettings, playerElementId) {
    this.playerElementId = playerElementId;
    this.createCTA(ctaSettings);
  }

  /**
   * Take the CTA settings and create the appropriate HTML element, storing it on the class instance
   *
   * @method createCTA
   * @private
   *
   * @param  {Object} ctaSettings - An object containing the cta label and
   *
   */
  createCTA(ctaSettings) {
    let url = ctaSettings.url;

    // Ensure that if the CTA didn't have a protocol, to default to basic http
    if ((url.indexOf('://') === -1) && (url.toLowerCase().indexOf('mailto:') === -1)) {
      url = `http://${url}`;
    }

    this.ctaElement = document.createElement('a');
    this.ctaElement.innerHTML = ctaSettings.title;
    this.ctaElement.setAttribute('href', url);
    this.ctaElement.setAttribute('target', '_blank');
    this.ctaElement.className = 'player_cta';
  }

  /**
   * Append the CTA element to the DOM to make it visible
   *
   * @method showCTA
   * @public
   *
   */
  showCTA() {
    const playerElement = document.getElementById(this.playerElementId);
    if (this.ctaElement && !this.ctaElement.parentNode && playerElement) {
      playerElement.appendChild(this.ctaElement);
    }
  }

  /**
   * Remove the CTA element from the DOM
   *
   * @method hideCTA
   * @public
   *
   */
  hideCTA() {
    if (this.ctaElement && this.ctaElement.parentNode) {
      this.ctaElement.parentNode.removeChild(this.ctaElement);
    }
  }
}
