export default function convertOptions(options) {
  const newOptions = {};
  if (options.autostart !== undefined) {
    newOptions.autoplay = options.autostart;
    delete options.autostart;
  }
  if (options.repeat !== undefined) {
    newOptions.loop = options.repeat;
    delete options.repeat;
  }
  return { ...options, ...newOptions };
}
